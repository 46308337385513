<template lang="pug">
component(:is="layout")
  router-view(v-slot="{ Component, route }")
    transition(name="route" mode="out-in")
      .h-full
        component(:is="Component")
</template>

<script>
import { AppHelpText } from "gignet-components";
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    Blank: defineAsyncComponent(() => import("@/layouts/Blank.vue")),
    Default: defineAsyncComponent(() => import("@/layouts/Default.vue")),
    AppHelpText,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || "Default";
    });

    return { layout };
  },
};
</script>

<style></style>
