<template lang="pug">
.login
  img(:src="require('@/assets/logo.png')")
  AppCard
    AppAlert.mb-4(title="Error" v-if="hasError")
    .login__title COLABORADORES
    form(@submit.prevent="doLogin")
      AppFormInput(type="text" value="eventura@gignet.mx" name="username" label="Email" :disabled="loading" required)
      AppFormInput.mb-2(type="password" value="qwerty123" name="password" label="Password" :disabled="loading" required)
      AppButton(:loading="loading" :disabled="loading" label="Login")
  AppHelpText(v-html="footerText")
</template>

<script>
import {
  AppButton,
  AppCard,
  AppAlert,
  AppFormInput,
  AppHelpText,
} from "gignet-components";
import { ref } from "@vue/reactivity";
import { axios, setToken } from "@/utils/axios";
import { useRouter } from "vue-router";
import { onError } from "@/utils/toastify";
export default {
  components: { AppButton, AppCard, AppAlert, AppFormInput, AppHelpText },
  setup() {
    const loading = ref(false);
    const router = useRouter();
    const hasError = ref(false);
    const footerText =
      "Al usar esta plataforma, estás de acuerdo con nuestras políticas de privacidad";

    const doLogin = async (e) => {
      try {
        if (loading.value) return true;
        loading.value = true;
        hasError.value = false;

        const body = new FormData(e.target);
        const { data } = await axios.post("expenses/signin", body);

        setToken(data.payload);
        router.push({ name: "ExpenseIndex" });
      } catch (error) {
        onError("Intenta de nuevo");
      } finally {
        loading.value = false;
      }
    };

    return { loading, hasError, footerText, doLogin };
  },
};
</script>

<style lang="scss" scoped>
.login {
  @apply min-h-full;
  @apply flex;
  @apply justify-evenly;
  @apply items-center;
  @apply flex-col;
  @apply mt-16;
  img {
    @apply mb-6;
    @apply w-2/3;
    @apply max-h-24;
    @apply w-auto;
    filter: drop-shadow(0, 2px 2px, rgba(0, 0, 0, 0.5));
  }
  &__title {
    @apply text-center;
    @apply text-bluegray-400;
    @apply uppercase;
    @apply text-base;
    @apply font-medium;
    @apply mb-2;
    @apply tracking-wide;
  }
}
</style>
