import { createToast } from "mosha-vue-toastify";

const onSuccess = (msg, timeout = 3000) => {
  createToast(
    {
      title: "Success!",
      description: msg,
    },
    {
      hideProgressBar: true,
      showIcon: true,
      type: "success",
      timeout: timeout,
    }
  );
};

const onError = (msg) => {
  createToast(
    {
      title: "Error!",
      description: msg,
    },
    {
      hideProgressBar: true,
      showIcon: true,
      type: "danger",
    }
  );
};

export { onSuccess, onError };
