import "@iconscout/unicons/css/line.css";
import "@iconscout/unicons/css/solid.css";
import "mosha-vue-toastify/dist/style.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "gignet-components/dist/gignet-components.css";
import "./assets/tailwind.css";
import ClickOutside from "@/directives/ClickOutside";

createApp(App).use(router).use(ClickOutside).mount("#app");
