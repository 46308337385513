import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { differenceInMinutes } from "date-fns";

import ChildView from "@/components/ChildView";
import Login from "@/views/Login";

import { setToken, clearToken } from "@/utils/axios";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      layout: "Blank",
      guest: true,
    },
  },
  {
    path: "/history",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "History" */ "@/views/History.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "@/views/Profile.vue"),
  },
  {
    path: "/expense",
    component: ChildView,
    children: [
      {
        path: "",
        name: "ExpenseIndex",
        component: () =>
          import(
            /* webpackChunkName: "ExpenseIndex" */ "@/views/Expense/Index.vue"
          ),
      },
      {
        path: "new",
        name: "ExpenseNew",
        component: () =>
          import(
            /* webpackChunkName: "ExpenseNew" */ "@/views/Expense/New.vue"
          ),
      },
      {
        path: ":id",
        name: "ExpenseDetails",
        component: () =>
          import(
            /* webpackChunkName: "ExpenseDetails" */ "@/views/Expense/Details.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const jwt = Cookies.get("gastos_at");
  let autenticado = false;

  if (jwt) {
    const decoded = jwt_decode(jwt);
    const d = differenceInMinutes(decoded.exp * 1000, Date.now());
    if (d <= 5) {
      if (d <= 0) {
        clearToken();
        autenticado = false;
      }
      // const { data } = await axios.post("/auth/refresh");
      // setToken(data.token);
    } else {
      setToken({ access_token: jwt });
      autenticado = true;
    }
  }

  if (!to.meta.guest) {
    if (autenticado) {
      next();
    } else {
      next({
        name: "Login",
        query: { redirectFrom: to.fullPath },
      });
    }
  } else {
    if (to.name === "Login" && autenticado) {
      next("/expense");
    } else {
      next();
    }
  }
});

export default router;
